<template>
    <ConfigCreate />
    <ConfigsList />
</template>
<script>
import ConfigCreate from '@/components/configs/ConfigCreate.vue'
import ConfigsList from '@/components/configs/ConfigsList.vue'
export default {
  name: 'ConfigsView',
  components: { ConfigsList, ConfigCreate }
}
</script>
