<template>
  <div class="card card-body mt-4">
    <h3>Edit users</h3>
    <form @submit.prevent="update">
      <div class="form-group">
        <label>First name</label>
        <input v-model="form.firstName" class="form-control" required />
      </div>
      <div class="form-group">
        <label>Last name</label>
        <input v-model="form.lastName" class="form-control" required />
      </div>

      <div class="form-group mt-3">
        <label>Email</label>
        <input v-model="form.email" class="form-control" type="email" required />
      </div>
      <div class="form-group mt-3">
        <label>Password</label>
        <input v-model="form.password" class="form-control" type="password" required />
      </div>
      <div class="form-group mt-3">
        <label>Phone number</label>
        <input v-model="form.phoneNumber" class="form-control" type="phone" required />
      </div>

      <button type="submit" class="btn btn-primary  mt-3">
        Update
      </button>
    </form>
  </div>
</template>

<script>
import { reactive, computed, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { getUser, updateUser } from '@/firebase'

export default {
  setup () {
    const router = useRouter()
    const route = useRoute()
    const userId = computed(() => route.params.id)

    const form = reactive({ name: '', email: '' })
    onMounted(async () => {
      const user = await getUser(userId.value)
      console.log(user, userId.value)
      form.firstName = user.firstName
      form.lastName = user.lastName
      form.email = user.email
      form.password = user.password
      form.phoneNumber = user.phoneNumber
    })

    const update = async () => {
      await updateUser(userId.value, { ...form })
      router.push('/')
      form.firstName = ''
      form.lastName = ''
    }

    return { form, update }
  }
}
</script>
