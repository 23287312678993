<template>
            <div class="form-group mt-5 ">
              <label>Choose File</label>
              <input type="file"   class="form-control" @change="upload" />
              <label>URL Image</label><br>
              <div class="form-control">
              <img :src="image2" alt="" width="200" height="150">
            </div>
              <div class="form-control mt-5">
                <span> {{ image2 }}</span>
              </div>
              <button  @click="onUpload" class="btn btn-primary mt-3">Send</button>
            </div>
</template>
<script>
import { ref, uploadBytes, getDownloadURL, getStorage } from 'firebase/storage'
export default {
  name: 'TestImage',
  data () {
    return {
      image1: '',
      preview: null,
      image2: null,
    }
  },
  methods: {
    upload (e) {
      this.image1 = e.target.files[0]
      console.log(this.image1)
    },
    onUpload () {
      console.log(this.image1.name)
      const storage = getStorage()
      const imageref = ref(storage, `imagesReviews/${this.image1.name}`)
      uploadBytes(imageref, this.image1).then(() => {
        getDownloadURL(imageref).then((url) => {
          // Insert url into an <img> tag to "download"
          this.image2 = url
          console.log(this.image2)
          console.warn(url)
        })
      })
    }
  }
}
</script>
