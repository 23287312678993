<template>
  <div class="card card-body mt-4">
    <h3>Edit users</h3>
    <form @submit.prevent="update">
      <div class="form-group">
        <label>Alias</label>
        <input v-model="form.alias" class="form-control" required />
      </div>
      <div class="form-group">
        <label>Title(ru)</label>
        <input v-model="form.title_ru" class="form-control" required />
      </div>
      <div class="form-group">
        <label>Title(en)</label>
        <input v-model="form.title_en" class="form-control" required />
      </div>
      <div class="form-group">
        <label>Title(uz)</label>
        <input v-model="form.title_uz" class="form-control" required />
      </div>
      <button type="submit" class="btn btn-primary  mt-3">
        Update
      </button>
    </form>
  </div>
</template>

<script>
import { reactive, computed, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { getConfig, updateConfig } from '@/firebase'

export default {
  setup () {
    const router = useRouter()
    const route = useRoute()
    const configId = computed(() => route.params.id)

    const form = reactive({ alias: '', title_en: '', title_ru: '', title_uz: '' })
    onMounted(async () => {
      const config = await getConfig(configId.value)
      console.log(config, configId.value)
      form.alias = config.alias
      form.title_en = config.title_en
      form.title_ru = config.title_ru
      form.title_uz = config.title_uz
    })

    const update = async () => {
      await updateConfig(configId.value, { ...form })
      router.push('/configs')
      form.alias = ''
      form.title_en = ''
      form.title_ru = ''
      form.title_uz = ''
    }

    return { form, update }
  }
}
</script>
