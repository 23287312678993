<template>
<router-link :to="`/add-donation`">
<button class="btn btn-primary btn-sm me-2">
Add donation
</button>
</router-link>
    <DonationsList />
</template>
<script>
import DonationsList from '@/components/donations/DonationsList.vue'
export default {
  name: 'DonationsView',
  components: { DonationsList }
}
</script>
