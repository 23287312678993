<template>
  <div class="header">
  <nav>
    <nav class="navbar navbar-expand-lg navbar flex-md-nowrap navbar-dark bg-dark">
  <a class="navbar-brand" href="/"><router-link to="/" class="header__logo" draggable="false">
                    <img src="https://epi-help.com/wp-content/uploads/2021/07/Logo.svg" height="50" alt="" title=""
                        draggable="false">
                </router-link></a>
     <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#menuItems" aria-controls="menuItems" aria-expanded="false" aria-label="Toggle Navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
  <div class="collapse navbar-collapse" id="menuItems">
    <ul class="navbar-nav">
      <a class="nav-item nav-link active" href="/"><router-link to="/">Static Page</router-link></a>&ensp;
      <a class="nav-item nav-link" href="/configs"> <router-link to="/configs">Config</router-link></a>&ensp;
      <a class="nav-item nav-link" href="/features"><router-link to="/features">Features</router-link></a>&ensp;
      <a class="nav-item nav-link" href="/questions"><router-link to="/questions">Questions</router-link></a>&ensp;
      <a class="nav-item nav-link" href="/reviews"><router-link to="/reviews">Reviews</router-link></a>&ensp;
      <a class="nav-item nav-link" href="/collaborations"><router-link to="/collaborations">With the upport of</router-link></a>&ensp;
      <a class="nav-item nav-link" href="/donations"><router-link to="/donations">Donations</router-link></a>&ensp;
      <a class="nav-item nav-link" href="/supports"><router-link to="/supports">Supports</router-link></a>&ensp;
      <a class="nav-item nav-link" href="/feedback"><router-link to="/feedback">Feedback</router-link></a>&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;
      <a class="nav-item nav-link" href="/Sign Out"><a  @click="signOutUser">Sign Out </a></a>&ensp;
      <a class="nav-item nav-link" href="/signup"><router-link to="/signup">Sign Up</router-link></a>&ensp;
    </ul>
  </div>
</nav>
    <!-- <router-link to="/">Users</router-link>
    | -->
    <!-- <span v-if="isLoggedIn">
      <button @click="signOutMe">Logout</button>
    </span> -->
    <!-- <span v-else>
      <router-link to="/register"> Register </router-link>
      <router-link to="/sign-in"> Login </router-link>
    </span> -->
  </nav>
</div>

  <router-view />
</template>
<script>
import { getAuth, signOut } from 'firebase/auth'
import { useAuthState } from './firebase'
import { useRouter } from 'vue-router'
export default {
  setup () {
    const { user } = useAuthState()
    const auth = getAuth()
    const router = useRouter()
    const signOutUser = async () => {
      try {
        await signOut(auth)
        router.push('/login')
      } catch (e) {
        alert(e.message)
      }
    }
    return { user, signOutUser }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #1b1c1b;
}
nav {
  padding: 0;
  padding-bottom: 80px;
}
nav a {
  font-weight: bold;
  color: #f8f8f8;
}

nav a.router-link-exact-active {
  color: #2df064;
}
.btn:focus {
  box-shadow: 0 0 0 3px #584def;
}
.btn:hover {
  color: #fff;
  background-color: #5f54f9;
  border-color: #4839f3;
}
.btn:active {
  color: #515add;
  background-color: #42b983;
  border-color: #42b983;
}
</style>
