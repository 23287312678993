<template>
<router-link :to="`/add-staticpage`">
<button class="btn btn-primary btn-sm me-2">
Add Static Page
</button>
</router-link>
    <StaticPagesList />
</template>
<script>
import StaticPagesList from '@/components/static_pages/StaticPagesList.vue'
export default {
  name: 'StaticPagesView',
  components: { StaticPagesList }
}
</script>
