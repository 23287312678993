<template>
  <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
		Status Bar
	  </button>
	  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
    <li><a class="dropdown-item" href="#">All</a></li>
		<li><a class="dropdown-item" href="#">New</a></li>
		<li><a class="dropdown-item" href="#">Review</a></li>
		<li><a class="dropdown-item" href="#">Completed</a></li>
	  </ul>
<div class="dropdown mb-5">
  <div class="card mt-4" >
    <table class="table m-0">
      <thead>
        <tr>
          <th scope="col">Name</th>
          <th scope="col">Email</th>
          <th scope="col">Description</th>
          <th scope="col">Type</th>
          <th scope="col">Status</th>
          <th scope="col">Control</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="{id, name, email, description, type, status } in supports" :key="id">
          <td>{{ name }}</td>
          <td>{{ email }}</td>
          <td>{{ description }}</td>
          <td>{{ type }}</td>
          <td>{{ status }}</td>
          <td>
            <router-link :to="`/edit-support/${id}`">
              <button class="btn btn-primary btn-sm me-2">
                Edit
              </button>
            </router-link>
            <button class="btn btn-danger btn-sm" @click="deleteSupport(id)">
              Delete
            </button>
          </td>
        </tr>
      </tbody>

    </table>
  </div>
  </div>
</template>

<script>
import { useLoadSupports, deleteSupport } from '@/firebase'
export default {
  setup () {
    const supports = useLoadSupports()

    return { supports, deleteSupport }
  }
}
</script>
