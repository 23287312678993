<template>
    <router-link :to="`/add-feedback`">
    <button class="btn btn-primary btn-sm me-2">
    Add feedback
    </button>
    </router-link>
        <FeedbackListVue />
    </template>
    <script>
    import FeedbackListVue from '@/components/feedback/FeedbackList.vue';
    export default {
      name: 'FeedBackView',
      components: { FeedbackListVue }
    }
    </script>
