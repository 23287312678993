<template>
    <UserCreate />
    <UserList />
</template>
<script>
import UserCreate from '@/components/user/UserCreate.vue'
import UserList from '@/components/user/UserList.vue'
export default {
  name: 'HomeView',
  components: { UserCreate, UserList }
}
</script>
