<template>
  <div class="card card-body mt-4">
    <h3>Add Donation</h3>
    <form @submit.prevent="onSubmit">
      <div class="row">
        <div class="col-6 form-group">
          <label>Nickname</label>
          <input v-model="form.nickname" class="form-control" required />
        </div>
        <div class="col-6 form-group">
          <label>Phone number</label>
          <input v-model="form.phone_number" class="form-control" required />
        </div>
      </div>
      <div class="row">
        <div class="col-3 form-check">
          <input class="form-check-input" type="checkbox" v-model="form.skype" id="flexCheckDefault">
          <label class="form-check-label" for="flexCheckDefault">
            Skype
          </label>
        </div>
        <div class="col-3 form-check">
          <input class="form-check-input" type="checkbox" v-model="form.whatsapp" id="flexCheckDefault">
          <label class="form-check-label" for="flexCheckDefault">
            WhatshApp
          </label>
        </div>
        <div class="col-3 form-check">
          <input class="form-check-input" type="checkbox" v-model="form.zoom" id="flexCheckDefault">
          <label class="form-check-label" for="flexCheckDefault">
            Zoom
          </label>
        </div>
        <div class="col-3 form-check">
          <input class="form-check-input" type="checkbox" v-model="form.messenger" id="flexCheckDefault">
          <label class="form-check-label" for="flexCheckDefault">
            Messenger
          </label>
        </div>
      </div>
      <button type="submit" class="btn btn-primary  mt-3">
        Create
      </button>
    </form>
  </div>
</template>

<script>

import { createDonation } from '@/firebase'
import { reactive } from 'vue'
import { useRouter } from 'vue-router'

export default {
  setup () {
    const router = useRouter()
    const form = reactive({
      nickname: '',
      phone_number: '',
      skype: false,
      whatsapp: false,
      zoom: false,
      messenger: false
    })
    const onSubmit = async () => {
      await createDonation({ ...form })
      router.push('/donations')
      form.nickname = ''
      form.phone_number = ''
      form.skype = false
      form.whatsapp = false
      form.zoom = false
      form.messenger = false
    }

    return {
      form,
      onSubmit
    }
  }
}
</script>
