<template>
    <div class="card card-body mt-4">
      <h3>Edit Feedback</h3>
    <form @submit.prevent="onSubmit">
      <div class="row">
          <div class="form-group col-6">
            <label>Name</label>
            <input v-model="form.name" type="text" class="form-control" required />
          </div>
          <div class="form-group col-6">
            <label>Phone</label>
            <input v-model="form.phone" type="phone" class="form-control" required />
          </div>
        </div>
        <div class="form-group ">
          <label>Email</label>
          <input v-model="form.email" class="form-control" required />
        </div>
        <div class="form-group ">
          <label>Comments</label>
          <textarea v-model="form.comments" class="form-control" required/>
        </div>
        <button type="submit" class="btn btn-primary  mt-3">
          Update
        </button>
      </form>
    </div>
  </template>

  <script>
  import { reactive, computed, onMounted } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { getFeedback, updateFeedback } from '@/firebase'

  export default {
    setup () {
      const router = useRouter()
      const route = useRoute()
      const feedbackId = computed(() => route.params.id)

      const form = reactive({
        name: '',
        phone: '',
        email: '',
        comments: ''
      })
      onMounted(async () => {
        const feedback = await getFeedback(feedbackId.value)
        form.name = feedback.name
        form.phone = feedback.phone
        form.email = feedback.email
        form.comments = feedback.comments
      })

      const onSubmit = async () => {
        await updateFeedback(feedbackId.value, { ...form })
        router.push('/feedback')
        form.name = ''
        form.phone = ''
        form.email = ''
        form.comments = ''
      }

      return {
        form,
        onSubmit
      }
    }
  }
  </script>
