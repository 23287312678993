<template>
  <div class="card card-body mt-4">
    <form @submit.prevent="onSubmit">
      <div class="form-group">
        <label>Alias</label>
        <input v-model="form.alias" class="form-control" required />
      </div>
      <div class="form-group">
        <label>Title(ru)</label>
        <input v-model="form.title_ru" class="form-control" required />
      </div>
      <div class="form-group">
        <label>Title(en)</label>
        <input v-model="form.title_en" class="form-control" required />
      </div>
      <div class="form-group">
        <label>Title(uz)</label>
        <input v-model="form.title_uz" class="form-control" required />
      </div>
      <button type="submit" class="btn btn-success mt-3">
        Create User
      </button>
    </form>
  </div>
</template>

<script>
import { createConfig } from '@/firebase'
import { reactive } from 'vue'

export default {
  setup () {
    const form = reactive({ alias: '', title_ru: '', title_en: '', title_uz: '' })

    const onSubmit = async () => {
      await createConfig({ ...form })
      form.alias = ''
      form.title_en = ''
      form.title_uz = ''
      form.title_ru = ''
    }

    return { form, onSubmit }
  }
}
</script>
