<template>
<router-link :to="`/add-question`">
<button class="btn btn-primary btn-sm me-2">
Add question
</button>
</router-link>
    <QuestionsList />
</template>
<script>
import QuestionsList from '@/components/questions/QuestionsList.vue'
export default {
  name: 'QuestionsView',
  components: { QuestionsList }
}
</script>
