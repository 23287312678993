<template>
  <div class="card mt-4">
    <table class="table m-0">
      <thead>
        <tr>
          <th scope="col">Alias</th>
          <th scope="col">Title</th>
          <th scope="col">Control</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="{id, alias, title_ru } in data" :key="id">
          <td>{{ alias }}</td>
          <td>{{ title_ru }}</td>
          <td>
            <router-link :to="`/edit-staticpage/${id}`">
              <button class="btn btn-primary btn-sm me-2">
                Edit
              </button>
            </router-link>
            <button class="btn btn-danger btn-sm" @click="deleteFeature(id)">
              Delete
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { useLoadStaticPages, deleteStaticPage } from '@/firebase'
export default {
  setup () {
    const data = useLoadStaticPages()
    return { data, deleteStaticPage }
  }
}
</script>
