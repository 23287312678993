<template>
  <div class="card mt-4">
    <table class="table m-0">
      <thead>
        <tr>
          <th scope="col">Title</th>
          <th scope="col">Description</th>
          <th scope="col">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="{id, title_ru, description_ru } in questions" :key="id">
          <td>{{ title_ru }}</td>
          <td>{{ description_ru }}</td>
          <td>
            <router-link :to="`/edit-question/${id}`">
              <button class="btn btn-primary btn-sm me-2">
                Edit
              </button>
            </router-link>
            <button class="btn btn-danger btn-sm" @click="deleteFeature(id)">
              Delete
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { useLoadQuestions, deleteQuestion } from '@/firebase'
export default {
  setup () {
    const questions = useLoadQuestions()
    return { questions, deleteQuestion }
  }
}
</script>
