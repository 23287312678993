<template>
<router-link :to="`/add-review`">
<button class="btn btn-primary btn-sm me-2">
Add review
</button>
</router-link>
    <ReviewsList />
</template>
<script>
import ReviewsList from '@/components/reviews/ReviewsList.vue'
export default {
  name: 'ReviewsView',
  components: { ReviewsList }
}
</script>
