import { createRouter, createWebHistory } from 'vue-router'
import { getUserState } from '../firebase'
import UserView from '../views/user/UserView.vue'
import UserEditView from '../views/user/UserEditView.vue'
import FeaturesView from '../views/features/FeaturesView.vue'
import FeatureEditView from '../views/features/FeatureEditView.vue'
import FeatureAddView from '../views/features/FeatureAddView.vue'

import StaticPagesView from '../views/static_pages/StaticPagesView.vue'
import StaticPageEditView from '../views/static_pages/StaticPageEditView.vue'
import StaticPageAddView from '../views/static_pages/StaticPageAddView.vue'

import ConfigsView from '../views/configs/ConfigsView.vue'
import ConfigEditView from '../views/configs/ConfigEditView.vue'

import CollaborationsView from '../views/collaborations/CollaborationsView.vue'
import CollaborationEditView from '../views/collaborations/CollaborationEditView.vue'
import CollaborationAddView from '../views/collaborations/CollaborationAddView.vue'

import DonationsView from '../views/donations/DonationsView.vue'
import DonationEditView from '../views/donations/DonationEditView.vue'
import DonationAddView from '../views/donations/DonationAddView.vue'

import SupportsView from '../views/supports/SupportsView.vue'
import SupportEditView from '../views/supports/SupportEditView.vue'
import SupportAddView from '../views/supports/SupportAddView.vue'

import QuestionsView from '../views/questions/QuestionsView.vue'
import QuestionEditView from '../views/questions/QuestionEditView.vue'
import QuestionAddView from '../views/questions/QuestionAddView.vue'

import ReviewsView from '../views/reviews/ReviewsView.vue'
import ReviewEditView from '../views/reviews/ReviewEditView.vue'
import ReviewAddView from '../views/reviews/ReviewAddView.vue'
import ReviewUploadImageView from '../views/reviews/ReviewUploadImageView.vue'

import FeedbackView from '../views/feedback/FeedbackView.vue'
import FeedbackAddView from '../views/feedback/FeedbackAddView.vue'
import FeedbackEditView from '../views/feedback/FeedbackEditView.vue'

const routes = [
  {
    path: '/login',
    name: 'LoginView',
    component: () => import('../views/LoginView.vue'),
    meta: { requiresUnauth: false }
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: () => import('../views/SignUp.vue'),
    meta: { requiresUnauth: false }
  },
  {
    path: '/home',
    name: 'home',
    component: UserView,
    meta: { requiresAuth: true }
  },
  {
    path: '/user-edit/:id',
    name: 'UserEditView',
    component: UserEditView,
    meta: { requiresAuth: true }
  },
  {
    path: '/features',
    name: 'features',
    component: FeaturesView,
    meta: { requiresAuth: true }
  },
  {
    path: '/edit-feature/:id',
    name: 'FeatureEditView',
    component: FeatureEditView,
    meta: { requiresAuth: true }
  },
  {
    path: '/add-feature',
    name: 'FeatureAddView',
    component: FeatureAddView,
    meta: { requiresAuth: true }
  },
  {
    path: '/reviews',
    name: 'reviews',
    component: ReviewsView,
    meta: { requiresAuth: true }
  },
  {
    path: '/edit-review/:id',
    name: 'ReviewEditView',
    component: ReviewEditView,
    meta: { requiresAuth: true }
  },
  {
    path: '/upload-review',
    name: 'UploadReviewView',
    component: ReviewUploadImageView,
    meta: { requiresAuth: true }
  },
  {
    path: '/add-review',
    name: 'ReviewAddView',
    component: ReviewAddView,
    meta: { requiresAuth: true }
  },
  {
    path: '/questions',
    name: 'questions',
    component: QuestionsView,
    meta: { requiresAuth: true }
  },
  {
    path: '/edit-question/:id',
    name: 'QuestionEditView',
    component: QuestionEditView,
    meta: { requiresAuth: true }
  },
  {
    path: '/add-question',
    name: 'QuestionAddView',
    component: QuestionAddView,
    meta: { requiresAuth: true }
  },

  {
    path: '/',
    name: 'static-pages',
    component: StaticPagesView,
    meta: { requiresAuth: true }
  },
  {
    path: '/edit-staticpage/:id',
    name: 'StaticPageEditView',
    component: StaticPageEditView,
    meta: { requiresAuth: true }
  },
  {
    path: '/add-staticpage',
    name: 'StaticPageAddView',
    component: StaticPageAddView,
    meta: { requiresAuth: true }
  },

  {
    path: '/configs',
    name: 'configs',
    component: ConfigsView,
    meta: { requiresAuth: true }
  },
  {
    path: '/edit-config/:id',
    name: 'ConfigEditView',
    component: ConfigEditView,
    meta: { requiresAuth: true }
  },

  {
    path: '/collaborations',
    name: 'collaborations',
    component: CollaborationsView,
    meta: { requiresAuth: true }
  },
  {
    path: '/edit-collaboration/:id',
    name: 'CollaborationEditView',
    component: CollaborationEditView,
    meta: { requiresAuth: true }
  },
  {
    path: '/add-collaboration',
    name: 'CollaborationAddView',
    component: CollaborationAddView,
    meta: { requiresAuth: true }
  },

  {
    path: '/donations',
    name: 'donations',
    component: DonationsView,
    meta: { requiresAuth: true }
  },
  {
    path: '/edit-donation/:id',
    name: 'DonationEditView',
    component: DonationEditView,
    meta: { requiresAuth: true }
  },
  {
    path: '/add-donation',
    name: 'DonationAddView',
    component: DonationAddView,
    meta: { requiresAuth: true }
  },

  {
    path: '/supports',
    name: 'supports',
    component: SupportsView,
    meta: { requiresAuth: true }
  },
  {
    path: '/edit-support/:id',
    name: 'SupportEditView',
    component: SupportEditView,
    meta: { requiresAuth: true }
  },
  {
    path: '/add-support',
    name: 'SupportAddView',
    component: SupportAddView,
    meta: { requiresAuth: true }
  },
  {
    path: '/feedback',
    name: 'feedback',
    component: FeedbackView,
    meta: { requiresAuth: true }
  },
  {
    path: '/edit-feedback/:id',
    name: 'FeedbackEditView',
    component: FeedbackEditView,
    meta: { requiresAuth: true }
  },
  {
    path: '/add-feedback',
    name: 'FeedbackAddView',
    component: FeedbackAddView,
    meta: { requiresAuth: true }
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const requiresUnauth = to.matched.some(record => record.meta.requiresUnauth)

  const isAuth = await getUserState()

  if (requiresAuth && !isAuth) next('/login')
  else if (requiresUnauth && isAuth) next('/')
  else next()
})

export default router
