<template>
<router-link :to="`/add-collaboration`">
<button class="btn btn-primary btn-sm me-2">
Add
</button>
</router-link>
    <CollaborationsList />
</template>
<script>
import CollaborationsList from '@/components/collaborations/CollaborationsList.vue'
export default {
  name: 'CollaborationsView',
  components: { CollaborationsList }
}
</script>
