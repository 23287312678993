import fb from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { ref, computed, onMounted, onUnmounted } from 'vue'

const config = {
  apiKey: 'AIzaSyAx0tpItthv56704OqmcShQa1d0gPkjqqk',
  authDomain: 'epi-help.firebaseapp.com',
  projectId: 'epi-help',
  storageBucket: 'epi-help.appspot.com',
  messagingSenderId: '680627327579',
  appId: '1:680627327579:web:0d2c15552d946684eae57e',
  measurementId: 'G-CVYG0CE8PM'
}

// const config = {
//   apiKey: 'AIzaSyALAzsgwqEAotfCEDoa7wgoCjAM9AMlchs',
//   authDomain: 'admin-epi-help.firebaseapp.com',
//   projectId: 'admin-epi-help',
//   storageBucket: 'admin-epi-help.appspot.com',
//   messagingSenderId: '333064330538',
//   appId: '1:333064330538:web:e5e4a9c6565e3372659253',
//   measurementId: 'G-PWG6WKMR67'
// }

const firebaseApp = fb.initializeApp(config)

const db = firebaseApp.firestore()

export const getUserState = () =>
  new Promise((resolve, reject) =>
    onAuthStateChanged(getAuth(), resolve, reject)
  )

export const useAuthState = () => {
  const user = ref(null)
  const error = ref(null)

  const auth = getAuth()
  let unsubscribe
  onMounted(() => {
    unsubscribe = onAuthStateChanged(
      auth,
      u => (user.value = u),
      e => (error.value = e)
    )
  })
  onUnmounted(() => unsubscribe())

  const isAuthenticated = computed(() => user.value != null)

  return { user, error, isAuthenticated }
}

const questionsCollection = db.collection('questions')

export const createQuestion = question => {
  return questionsCollection.add(question)
}

export const getQuestion = async id => {
  const question = await questionsCollection.doc(id).get()
  return question.exists ? question.data() : null
}

export const updateQuestion = (id, question) => {
  return questionsCollection.doc(id).update(question)
}

export const deleteQuestion = id => {
  return questionsCollection.doc(id).delete()
}

export const useLoadQuestions = () => {
  const data = ref([])
  const close = questionsCollection.onSnapshot(snapshot => {
    data.value = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
  })
  onUnmounted(close)
  return data
}

const featuresCollection = db.collection('features')

export const createFeature = feature => {
  return featuresCollection.add(feature)
}

export const getFeature = async id => {
  const feature = await featuresCollection.doc(id).get()
  return feature.exists ? feature.data() : null
}

export const updateFeature = (id, feature) => {
  return featuresCollection.doc(id).update(feature)
}

export const deleteFeature = id => {
  return featuresCollection.doc(id).delete()
}

export const useLoadFeatures = () => {
  const data = ref([])
  const close = featuresCollection.onSnapshot(snapshot => {
    data.value = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
  })
  onUnmounted(close)
  return data
}

const reviewsCollection = db.collection('reviews')

export const createReview = review => {
  return reviewsCollection.add(review)
}

export const getReview = async id => {
  const review = await reviewsCollection.doc(id).get()
  return review.exists ? review.data() : null
}

export const updateReview = (id, review) => {
  return reviewsCollection.doc(id).update(review)
}

export const deleteReview = id => {
  return reviewsCollection.doc(id).delete()
}

export const useLoadReviews = () => {
  const data = ref([])
  const close = reviewsCollection.onSnapshot(snapshot => {
    data.value = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
  })
  onUnmounted(close)
  return data
}

const usersCollection = db.collection('users')

export const createUser = user => {
  return usersCollection.add(user)
}

export const getUser = async id => {
  const user = await usersCollection.doc(id).get()
  return user.exists ? user.data() : null
}

export const updateUser = (id, user) => {
  return usersCollection.doc(id).update(user)
}

export const deleteUser = id => {
  return usersCollection.doc(id).delete()
}

export const useLoadUsers = () => {
  const users = ref([])
  const close = usersCollection.onSnapshot(snapshot => {
    users.value = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
  })
  onUnmounted(close)
  return users
}

const supportsCollection = db.collection('supports')

export const createSupport = support => {
  return supportsCollection.add(support)
}

export const getSupport = async id => {
  const support = await supportsCollection.doc(id).get()
  return support.exists ? support.data() : null
}

export const updateSupport = (id, support) => {
  return supportsCollection.doc(id).update(support)
}

export const deleteSupport = id => {
  return supportsCollection.doc(id).delete()
}

export const useLoadSupports = () => {
  const users = ref([])
  const close = supportsCollection.onSnapshot(snapshot => {
    users.value = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
    console.log(users.value)
  })
  onUnmounted(close)
  return users
}

const donationsCollection = db.collection('donations')

export const createDonation = donation => {
  return donationsCollection.add(donation)
}

export const getDonation = async id => {
  const donation = await donationsCollection.doc(id).get()
  return donation.exists ? donation.data() : null
}

export const updateDonation = (id, donation) => {
  return donationsCollection.doc(id).update(donation)
}

export const deleteDonation = id => {
  return donationsCollection.doc(id).delete()
}

export const useLoadDonations = () => {
  const donations = ref([])
  const close = donationsCollection.onSnapshot(snapshot => {
    donations.value = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
  })
  onUnmounted(close)
  return donations
}

const collaborationsCollection = db.collection('collaborations')

export const createCollaboration = collaboration => {
  return collaborationsCollection.add(collaboration)
}

export const getCollaboration = async id => {
  const collaboration = await collaborationsCollection.doc(id).get()
  return collaboration.exists ? collaboration.data() : null
}

export const updateCollaboration = (id, collaboration) => {
  return collaborationsCollection.doc(id).update(collaboration)
}

export const deleteCollaboration = id => {
  return collaborationsCollection.doc(id).delete()
}

export const useLoadCollaborations = () => {
  const collaborations = ref([])
  const close = collaborationsCollection.onSnapshot(snapshot => {
    collaborations.value = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
  })
  onUnmounted(close)
  return collaborations
}

const configsCollection = db.collection('configs')

export const createConfig = config => {
  return configsCollection.add(config)
}

export const getConfig = async id => {
  const config = await configsCollection.doc(id).get()
  return config.exists ? config.data() : null
}

export const updateConfig = (id, config) => {
  return configsCollection.doc(id).update(config)
}

export const deleteConfig = id => {
  return configsCollection.doc(id).delete()
}

export const useLoadConfigs = () => {
  const users = ref([])
  const close = configsCollection.onSnapshot(snapshot => {
    users.value = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
  })
  onUnmounted(close)
  return users
}

const staticpagesCollection = db.collection('staticpages')

export const createStaticPage = staticpage => {
  return staticpagesCollection.add(staticpage)
}

export const getStaticPage = async id => {
  const staticpage = await staticpagesCollection.doc(id).get()
  return staticpage.exists ? staticpage.data() : null
}

export const updateStaticPage = (id, staticpage) => {
  return staticpagesCollection.doc(id).update(staticpage)
}

export const deleteStaticPage = id => {
  return staticpagesCollection.doc(id).delete()
}

export const useLoadStaticPages = () => {
  const staticpages = ref([])
  const close = staticpagesCollection.onSnapshot(snapshot => {
    staticpages.value = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
  })
  onUnmounted(close)
  return staticpages
}

const feedbackCollection = db.collection('feedback')

export const createFeedback = feedback => {
  return feedbackCollection.add(feedback)
}

export const getFeedback = async id => {
  const feedback = await feedbackCollection.doc(id).get()
  return feedback.exists ? feedback.data() : null
}

export const updateFeedback = (id, feedback) => {
  return feedbackCollection.doc(id).update(feedback)
}

export const deleteFeedback = id => {
  return feedbackCollection.doc(id).delete()
}

export const useLoadFeedback = () => {
  const data = ref([])
  const close = feedbackCollection.onSnapshot(snapshot => {
    data.value = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
  })
  onUnmounted(close)
  return data
}
