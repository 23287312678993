<template>
  <div class="card card-body mt-4">
    <h3>Review Add</h3>
    <form @submit.prevent="onSubmit">
      <ul class="nav nav-pills mb-3" >
          <li class="nav-item" role="presentation">
            <router-link :to="`/upload-review`">
              <button class="btn btn-primary btn-sm me-2">
                Upload Image
              </button>
            </router-link>
          </li>
        </ul>
      <div class="form-group">
        <label>Title</label>
        <input v-model="form.title" class="form-control" required />
      </div>
      <div class="form-group">
        <label>Description</label>
        <textarea class="form-control" rows="10" v-model="form.description"
          placeholder="add multiple lines"></textarea>
      </div>
      <div class="form-group">
        <label>Image</label>
        <input v-model="form.image" class="form-control" required />
      </div>

      <button type="submit" class="btn btn-primary  mt-3">
        Create
      </button>
    </form>
  </div>
</template>

<script>

import { createReview } from '@/firebase'
import { reactive } from 'vue'
import { useRouter } from 'vue-router'

export default {
  setup () {
    const router = useRouter()
    const form = reactive({
      title: '',
      description: '',
      image: ''
    })
    const onSubmit = async () => {
      await createReview({ ...form })
      router.push('/reviews')
      form.title = ''
      form.description = ''
      form.image = ''
    }

    return {
      form,
      onSubmit
    }
  }
}
</script>
