<template>
  <div class="card card-body mt-4">
    <h3>Collaboration Edit</h3>
    <form @submit.prevent="onSubmit">
      <div>
        <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
          <li class="nav-item" role="presentation">
            <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home"
                    type="button" role="tab" aria-controls="pills-home" aria-selected="true">Russian
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile"
                    type="button" role="tab" aria-controls="pills-profile" aria-selected="false">English
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact"
                    type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Uzbek
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="pills-image-tab" data-bs-toggle="pill" data-bs-target="#pills-image"
              type="button" role="tab" aria-controls="pills-image" aria-selected="false">Upload Image
            </button>
          </li>
        </ul>
        <div class="tab-content" id="pills-tabContent">
          <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
            <div class="form-group">
              <label>Title(ru)</label>
              <input v-model="form.title_ru" class="form-control" required/>
            </div>
            <div class="form-group">
              <label>Description(ru)</label>
              <textarea class="form-control" rows="10" v-model="form.description_ru"
                        placeholder="add multiple lines"></textarea>
            </div>
            <div class="form-group">
        <label>Image</label>
        <input v-model="form.image" class="form-control" required/></div>

      <button type="submit" class="btn btn-primary  mt-3">
        Update
      </button>
          </div>
          <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
            <div class="form-group">
              <label>Title(en)</label>
              <input v-model="form.title_en" class="form-control" required/>
            </div>
            <div class="form-group">
              <label>Description(en)</label>
              <textarea class="form-control" rows="10" v-model="form.description_en"
                        placeholder="add multiple lines"></textarea>
            </div>
            <div class="form-group">
        <label>Image</label>
        <input v-model="form.image" class="form-control" required/></div>

      <button type="submit" class="btn btn-primary  mt-3">
        Update
      </button>
          </div>
          <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
            <div class="form-group">
              <label>Title(uz)</label>
              <input v-model="form.title_uz" class="form-control" required/>
            </div>
            <div class="form-group">
              <label>Description(uz)</label>
              <textarea class="form-control" rows="10" v-model="form.description_uz"
                        placeholder="add multiple lines"></textarea>
            </div>
            <div class="form-group">
        <label>Image</label>
        <input v-model="form.image" class="form-control" required/></div>

      <button type="submit" class="btn btn-primary  mt-3">
        Update
      </button>
          </div>
        </div>
      </div>
    </form>
    <div class="tab-pane fade form-control" id="pills-image" role="tabpanel" aria-labelledby="pills-image-tab">
            <div class="form-group">
              <label>Choose File</label>
              <input type="file"   class="form-control" @change="upload" />
              <label>URL Image</label><br>
              <div class="form-control">
              <img :src="image2" alt="" width="200" height="150">
            </div>
              <div class="form-control">
                <span> {{ image2 }}</span>
              </div>
              <button  @click="onUpload" class="btn btn-primary mt-3">Send</button>
            </div>
          </div>
  </div>
</template>

<script>
import { reactive, computed, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { getCollaboration, updateCollaboration } from '@/firebase'
import { ref, uploadBytes, getDownloadURL, getStorage } from 'firebase/storage'
export default {
  name: 'TestImage',
  data () {
    return {
      image1: '',
      preview: null,
      image2: null,
    }
  },
  methods: {
    upload (e) {
      this.image1 = e.target.files[0]
      console.log(this.image1)
    },
    onUpload () {
      console.log(this.image1.name)
      const storage = getStorage()
      const imageref = ref(storage, `imagesCollabration/${this.image1.name}`)
      uploadBytes(imageref, this.image1).then(() => {
        getDownloadURL(imageref).then((url) => {
          // Insert url into an <img> tag to "download"
          this.image2 = url
          console.log(this.image2)
          console.warn(url)
        })
      })
    }
  },
  setup () {
    const router = useRouter()
    const route = useRoute()
    const collaborationId = computed(() => route.params.id)

    const form = reactive({
      title_ru: '',
      description_ru: '',
      title_en: '',
      description_en: '',
      title_uz: '',
      description_uz: '',
      image: ''
    })
    onMounted(async () => {
      const collaboration = await getCollaboration(collaborationId.value)
      console.log(collaboration, collaborationId.value)
      form.title_ru = collaboration.title_ru
      form.description_ru = collaboration.description_ru
      form.title_en = collaboration.title_en
      form.description_en = collaboration.description_en
      form.title_uz = collaboration.title_uz
      form.description_uz = collaboration.description_uz
      form.image = collaboration.image
    })

    const onSubmit = async () => {
      await updateCollaboration(collaborationId.value, { ...form })
      router.push('/collaborations')
      form.title_ru = ''
      form.description_ru = ''
      form.title_en = ''
      form.description_en = ''
      form.title_uz = ''
      form.description_uz = ''
      form.image = ''
    }

    return {
      form,
      onSubmit
    }
  }
}
</script>
