<template>
  <div class="card card-body mt-4">
    <h3>Support</h3>
    <form @submit.prevent="onSubmit">
      <div class="row">
        <div class="form-group col-6">
          <label>Name</label>
          <input v-model="form.name" class="form-control" required />
        </div>
        <div class="form-group col-6">
          <label>Email</label>
          <input v-model="form.email" class="form-control" required />
        </div>
      </div>
      <div class="form-group">
        <label>Description</label>
        <input v-model="form.description" class="form-control" required />
      </div>
      <div class="row">
        <div class="form-group col-6">
          <label>Type</label>
          <select v-model="form.type" class="form-select" aria-label="Default select example">
            <option value="Question " >Question</option>
            <option value="Membership and Account">Membership and Account</option>
            <option value="App Setup and Using">App Setup and Using</option>
            <option value="Feature Request">Feature Request</option>
            <option value="Reports and Payments">Reports and Payments</option>
            <option value="Lost Password">Lost Password</option>
            <option value="Report a Concern">Report a Concern</option>
            <option value="Feedback">Feedback</option>
            <option value="Contact us">Contact us</option>
          </select>
        </div>
        <div class="form-group col-6">
          <label>Status</label>
          <select v-model="form.status" class="form-select" aria-label="Default select example">
            <option value="new">New</option>
            <option value="review">Review</option>
            <option value="completed">Completed</option>
          </select>
        </div>
      </div>
      <button type="submit" class="btn btn-primary  mt-3">
        Create
      </button>
    </form>
  </div>
</template>

<script>

import { createSupport } from '@/firebase'
import { reactive } from 'vue'
import { useRouter } from 'vue-router'

export default {
  setup () {
    const router = useRouter()
    const form = reactive({
      name: '',
      email: '',
      description: '',
      type: '',
      status: ''
    })
    const onSubmit = async () => {
      await createSupport({ ...form })
      router.push('/supports')
      form.name = ''
      form.email = ''
      form.description = ''
      form.type = ''
      form.status = ''
    }

    return {
      form,
      onSubmit
    }
  }
}
</script>
