<template>
    <div class="card mt-4">
      <table class="table m-0">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Phone</th>
            <th scope="col">Email</th>
            <th scope="col">Comments</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="{id, name, phone, email, comments,  } in feedback" :key="id">
            <td>{{ name }}</td>
            <td>{{ phone }}</td>
            <td>{{ email }}</td>
            <td>{{ comments }}</td>
            <td>
              <router-link :to="`/edit-feedback/${id}`">
                <button class="btn btn-primary btn-sm me-2">
                  Edit
                </button>
              </router-link>
              <button class="btn btn-danger btn-sm" @click="deleteFeedback(id)">
                Delete
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>

  <script>
  import { useLoadFeedback, deleteFeedback } from '@/firebase'
  export default {
    setup () {
      const feedback = useLoadFeedback()
      return { feedback, deleteFeedback } }
    }
  </script>
