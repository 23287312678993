<template>
  <div class="card card-body mt-4">
    <h3>Question Add</h3>
    <form @submit.prevent="onSubmit">
      <div>
        <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
          <li class="nav-item" role="presentation">
            <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home"
                    type="button" role="tab" aria-controls="pills-home" aria-selected="true">Russian
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile"
                    type="button" role="tab" aria-controls="pills-profile" aria-selected="false">English
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact"
                    type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Uzbek
            </button>
          </li>
        </ul>
        <div class="tab-content" id="pills-tabContent">
          <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
            <div class="form-group">
              <label>Title(ru)</label>
              <input v-model="form.title_ru" class="form-control" required/>
            </div>
            <div class="form-group">
              <label>Description(ru)</label>
              <textarea class="form-control" rows="10" v-model="form.description_ru"
                        placeholder="add multiple lines"></textarea>
            </div>
          </div>
          <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
            <div class="form-group">
              <label>Title(en)</label>
              <input v-model="form.title_en" class="form-control" required/>
            </div>
            <div class="form-group">
              <label>Description(en)</label>
              <textarea class="form-control" rows="10" v-model="form.description_en"
                        placeholder="add multiple lines"></textarea>
            </div>
          </div>
          <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
            <div class="form-group">
              <label>Title(uz)</label>
              <input v-model="form.title_uz" class="form-control" required/>
            </div>
            <div class="form-group">
              <label>Description(uz)</label>
              <textarea class="form-control" rows="10" v-model="form.description_uz"
                        placeholder="add multiple lines"></textarea>
            </div>
          </div>
        </div>
      </div>

      <button type="submit" class="btn btn-primary  mt-3">
        Create
      </button>
    </form>
  </div>
</template>

<script>

import { createQuestion } from '@/firebase'
import { reactive } from 'vue'
import { useRouter } from 'vue-router'

export default {
  setup () {
    const router = useRouter()
    const form = reactive({
      title_ru: '',
      description_ru: '',
      title_en: '',
      description_en: '',
      title_uz: '',
      description_uz: ''
    })
    const onSubmit = async () => {
      await createQuestion({ ...form })
      router.push('/questions')
      form.title_ru = ''
      form.description_ru = ''
      form.title_en = ''
      form.description_en = ''
      form.title_uz = ''
      form.description_uz = ''
    }

    return {
      form,
      onSubmit
    }
  }
}
</script>
