<template>
    <div class="card card-body mt-4">
      <h3>Feedback Add</h3>
      <form @submit.prevent="onSubmit">
        <div class="row">
          <div class="form-group col-6">
            <label>Name</label>
            <input v-model="form.name" type="text" class="form-control" required />
          </div>
          <div class="form-group col-6">
            <label>Phone Number</label>
            <input v-model="form.phone" type="number" class="form-control" required />
          </div>
        <div class="form-group ">
          <label>Email</label>
          <input v-model="form.email" type="email" class="form-control" required />
        </div>
      </div>
        <div class="form-group">
          <label>Comments</label>
          <textarea v-model="form.comments" type="text" class="form-control" required></textarea>
        </div>
        <button type="submit" class="btn btn-primary  mt-3">
          Create
        </button>
      </form>
    </div>
  </template>

  <script>

  import { createFeedback } from '@/firebase'
  import { reactive } from 'vue'
  import { useRouter } from 'vue-router'

  export default {
    setup () {
      const router = useRouter()
      const form = reactive({
        name: '',
        phone: '',
        email: '',
        comments: ''
      })
      const onSubmit = async () => {
        await createFeedback({ ...form })
        router.push('/feedback')
        form.name = ''
        form.phone = ''
        form.email = ''
        form.comments = ''
      }

      return {
        form,
        onSubmit
      }
    }
  }
  </script>
