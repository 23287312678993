<template>
<router-link :to="`/add-feature`">
<button class="btn btn-primary btn-sm me-2">
Add
</button>
</router-link>
    <FeaturesList />
</template>
<script>
import FeaturesList from '@/components/features/FeaturesList.vue'
export default {
  name: 'FeaturesView',
  components: { FeaturesList }
}
</script>
