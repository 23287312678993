<template>
<router-link :to="`/add-support`">
<button class="btn btn-primary btn-sm me-2">
Add support
</button>
</router-link>
    <SupportsList />
</template>
<script>
import SupportsList from '@/components/supports/SupportsList.vue'
export default {
  name: 'SupportsView',
  components: { SupportsList }
}
</script>
